 
<template>
  <vx-card style="padding-left: 10px">
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="marketName">Name</label>
          <p id="marketName">
            {{ market.marketName ? market.marketName : "N/A" }}
          </p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="displayAddress">Address</label>
          <p id="displayAddress">
            {{ market.displayAddress ? market.displayAddress : "N/A" }}
          </p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="startData">Start</label>
          <p id="startData">
            {{
              market.startDate
                ? moment(market.startDate).format("DD/MM/YYYY ")
                : "N/A"
            }}
          </p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="endDate">End</label>
          <p id="endDate">
            {{
              market.endDate
                ? moment(market.endDate).format("DD/MM/YYYY ")
                : "N/A"
            }}
          </p>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "MarketDetails",
  data() {
    return {
      market: {},
    };
  },

  methods: {
    ...mapActions("market", ["fetchMarket"]),
    moment(date) {
      return moment(date);
    },
    getMarketDetail(id) {
      let self = this;
      this.fetchMarket(id).then((res) => {
        this.market = res.data.data;
      });
    },
  },

  created() {
    let marketId = this.$route.params.id;
    this.getMarketDetail(marketId);
  },
};
</script> 
<style >
.ql-editor {
  min-height: 200px;
}
</style> 